import { useState, FormEvent } from 'react';
import firebase from 'firebase';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { api } from '../../../services/api';
import { useAuth } from '../../../hooks/useAuth';

// import './styles.css';

interface ForgotPasswordModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

interface FindUserData {
  clientId: string;
  cpf: string;
  createdAt: number;
  email: string;
  master: boolean;
  name: string;
  uid: string;
  updatedAt: number;
}

export function ForgotPasswordModal({
  isOpen,
  onCloseModal,
}: ForgotPasswordModalProps) {
  // const props = { isOpen, onCloseModal };
  // console.log('propss-->', props);

  const { client } = useAuth();

  const [userEmail, setUserEmail] = useState('');
  const [recoveringPassword, setRecoveringPassword] = useState(false);

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    // console.log('userEmail-->', userEmail);
    // console.log('client-->', client);
    setRecoveringPassword(true);

    let parameters = {
      searchFunctionality: 'getUserByEmail',
      userType: 'appScan',
      clientId: client?.uid,
      searchEmail: userEmail,
    };

    try {
      const { data } = await api.post(`/appScan`, parameters);
      // setRecoveringPassword(false);
      // console.log('getUserByEmail-->', data);
      recoverPassword(data.data);
    } catch (error) {
      setRecoveringPassword(false);
      console.log('erro handleFormSubmit', error);
      alert('Não encontramos o usuário com o email inserido!');
    }
  }

  function recoverPassword(user: FindUserData) {
    // console.log('user-->', user);
    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(() => {
        onCloseModal();
        alert(
          `Encaminhamos para o email ${user.email} as instruções para recuperação da senha`
        );
        setRecoveringPassword(false);
      })
      .catch((error) => {
        console.log('sendPasswordResetEmail error:', error);
        alert(
          `Tivemos um problema para enviar o e-mail com as instruções para recuperação da senha, verifique o e-mail informado ou tente novamente mais tarde!`
        );
        setRecoveringPassword(false);
      });
  }

  return (
    <Modal
      // showCloseIcon={false}
      open={isOpen}
      onClose={onCloseModal}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-modal',
        modalContainer: 'oq-container',
        closeButton: 'btn btn-floating z-depth-0 transparent waves-effect',
      }}
    >
      <form onSubmit={handleFormSubmit}>
        <div className="oq-wrapper-contents">
          <h1 className="title-alert title-normal">Recuperar senha</h1>
          <p className="content-alert">
            Informe seu e-mail para prosseguirmos com a recuperação da sua
            conta.
          </p>
          <br />
          <div className="oq-input-field">
            <label htmlFor="inputPassword">E-mail</label>
            <input
              type="email"
              name="email"
              id="inputPassword"
              placeholder="seu@email.com"
              required={true}
              onChange={(event) => setUserEmail(event.target.value)}
            />
          </div>
        </div>
        <div className="oq-group-horizontal">
          <button
            className="oq-btn btn-confirm white-text full-w blue waves-effect waves-light"
            type="submit"
            disabled={recoveringPassword}
          >
            {recoveringPassword ? 'Resetando ...' : 'Recuperar a senha'}
          </button>
        </div>
        <div></div>
      </form>
    </Modal>
  );
}
