import { Dropdown, Button, Icon } from 'react-materialize';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../../firebase';
import { useAuth } from '../../../../hooks/useAuth';
import * as routes from '../../../../routes/constants';
import './style.css';

export function Header() {
  const history = useHistory();
  const { client } = useAuth();

  function logout() {
    // console.log('clicou em sairr');
    auth.doSignOut().then(() => {
      // console.log('deu certo');
      history.push(routes.START);
    });
  }

  return (
    <div className="navbar-fixed">
      <nav className="z-depth-0 white">
        <div className="container" style={{ height: '100%' }}>
          <div className="nav-wrapper flex">
            <div className="content-left">
              <img
                src={client?.logoURL ? client?.logoURL : ''}
                id="business-logo"
                className="responsive-img"
                alt=""
              />
            </div>
            <div className="content-center"></div>
            <div className="content-right">
              <Dropdown
                id="Dropdown_6"
                options={{
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  container: null,
                  coverTrigger: true,
                  hover: false,
                  inDuration: 150,
                  outDuration: 250,
                }}
                trigger={
                  <Button
                    className="dropdown-trigger btn-floating white z-depth-0 waves-effect"
                    node="button"
                  >
                    <Icon className="grey-text text-darken-3">more_vert</Icon>
                  </Button>
                }
              >
                <button
                  onClick={logout}
                  className="btn-item-dropdown waves-effect"
                >
                  Sair
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
