import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Login } from '../pages/Login';
import { ScanQr } from '../pages/ScanQr';
import { Details } from '../pages/Details';
import { Dashboard } from '../pages/Dashboard';
import { Profile } from '../pages/Profile';
import { NotFound } from '../pages/NotFound';
import { ConfirmRescueDetails } from '../pages/ConfirmRescueDetails';
import { PrivateRoute } from './PrivateRoute';

import * as routes from './constants';

export function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={routes.START} component={Login} exact={true} />
        <Route
          exact
          path={`${routes.UNDERLINE}/:clientAlias`}
          component={Login}
        />

        <PrivateRoute
          isPrivate
          path={routes.SCAN}
          component={ScanQr}
          exact={true}
        />
        <PrivateRoute
          isPrivate
          path={`${routes.DETAILS}/:typelist`}
          component={Details}
          exact={true}
        />
        <PrivateRoute
          isPrivate
          path={routes.DASHBOARD}
          component={Dashboard}
          exact={true}
        />
        <PrivateRoute
          isPrivate
          path={routes.PROFILE}
          component={Profile}
          exact={true}
        />
        <PrivateRoute
          isPrivate
          path={routes.CONFIRM_RESCUE_DETAILS}
          component={ConfirmRescueDetails}
          exact={true}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
