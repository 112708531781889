import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { useAuth } from '../../hooks/useAuth';
import './style.css';
import { auth } from '../../firebase';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

export function Profile() {
  const history = useHistory();

  const { client, user, verifyOnAuthStateChanged } = useAuth();

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  function backPage() {
    history.goBack();
  }

  function handleUserImage(e: any, ref: any, fileIndex: any) {
    // console.log('e.target.files--->>', e.target.files);
    // console.log('e.target.files[0]--->>', e.target.files[0]);
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    // this.setState({ isUploading: true });
    setIsUploadingAvatar(true);
    if (size < 1000000) {
      handleUploadSuccessImage(e.target.files[0], randomizeName, ref);
    } else {
      setIsUploadingAvatar(false);
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  function handleUploadSuccessImage(file: any, filename: any, ref: any) {
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            console.log('url-->', url);
            await updateAvatarUser(url);
          });
      });
  }

  async function updateAvatarUser(userAvatar: string) {
    try {
      const authToken = await auth.getAuthUserToken();

      if (!authToken) {
        setIsUploadingAvatar(false);
        alert('Falha na autenticação');
        return;
      }

      const parameters = {
        searchFunctionality: 'updateUserData',
        userType: 'appScan',
        userId: user?.uid,
        userAvatar,
      };

      const requestConfig = {
        headers: { Authorization: authToken },
      };

      // const { data } =
      await api.post(`/appScan`, parameters, requestConfig);
      setIsUploadingAvatar(false);

      // console.log('responseee-->', data);
      verifyOnAuthStateChanged();
    } catch (error) {
      setIsUploadingAvatar(false);
      console.log('error postDataQrCodeScan-->', error);
      alert('Falha ao atualizar foto');
    }
  }

  return (
    <>
      <div className="navbar-fixed">
        <Helmet>
          <title>
            {`${client?.companyName ? client?.companyName : 'OQ Digital'}`} - OQ
            Scan
          </title>
          <link
            rel="shortcut icon"
            href={client?.favIcon ? client?.favIcon : ''}
          />
        </Helmet>
        <nav className="white z-depth-0">
          <div className="nav-wrapper">
            <div className="content-left">
              <span className="btn-floating white z-depth-0">
                <i
                  onClick={backPage}
                  className="material-icons grey-text text-darken-3"
                >
                  arrow_back
                </i>
              </span>
              <span className="grey-text text-darken-3 topbar-title">
                Meu perfil
              </span>
            </div>
            <div className="content-right"></div>
          </div>
        </nav>
      </div>
      <div className="container">
        <div className="row">
          <div className="col s12">
            <div className="card z-depth-0">
              <div className="card-content center">
                {isUploadingAvatar ? (
                  <Loading type="" />
                ) : (
                  <img
                    src={`${
                      user?.avatar
                        ? user.avatar
                        : 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                    }`}
                    width="100"
                    alt=""
                    className="circle"
                  />
                )}
                <br />
                <br />
                <p id="username">{user?.name}</p>
                <p id="user-email">{user?.email}</p>
              </div>
              <div className="d-flex-justify-center">
                <input
                  placeholder="Atualizar foto"
                  type="file"
                  accept="image/*"
                  id="profilePic"
                  onChange={(event) =>
                    handleUserImage(event, 'UsersAvatar/', 'avatar')
                  }
                  className="inputfile"
                />
                <label htmlFor="profilePic" className="waves-effect">
                  <div
                    className="d-flex-justify-center"
                    style={{ padding: '10px 20px 10px 20px' }}
                  >
                    <svg
                      style={{ marginRight: '10px' }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                    >
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                    </svg>

                    <span>Atualizar foto</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="col s12 m6">
            <div className="input-field">
              <input
                value={user?.name}
                type="text"
                className="browser-default custom-input"
                placeholder="Nome"
                disabled
              />
            </div>
          </div>
          <div className="col s12 m6">
            <div className="input-field">
              <input
                value={user?.email}
                type="text"
                className="browser-default custom-input"
                placeholder="E-mail"
                disabled
              />
            </div>
          </div>
          <div className="col s12 m6">
            <div className="input-field">
              <input
                value={user?.cpf}
                type="text"
                className="browser-default custom-input"
                placeholder="CNPJ"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
