import { useHistory } from 'react-router-dom';

import * as routes from '../../routes/constants';

export function Footer() {
  const history = useHistory();

  function goToScannerPage() {
    history.push(routes.SCAN);
  }

  function goToProfilePage() {
    history.push(routes.PROFILE);
  }

  function goToDashboardPage() {
    history.push(routes.DASHBOARD);
  }

  return (
    <div className="navbar-fixed-bottom">
      <nav className="white z-depth-3">
        <div className="nav-wrapper flex">
          <div className="content-left">
            <button
              onClick={goToDashboardPage}
              className="btn-label waves-effect"
            >
              <p className="material-icons-outlined">dashboard</p>
              <span>Dashboard</span>
            </button>
          </div>
          <div className="content-center">
            <button
              onClick={goToScannerPage}
              className="btn-floating action z-depth-0 blue waves-effect"
            >
              <i className="material-icons">qr_code</i>
            </button>
          </div>
          <div className="content-right">
            <button
              onClick={goToProfilePage}
              className="btn-label waves-effect"
            >
              <p className="material-icons-outlined">person</p>
              <span>Meu perfil</span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}
