import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { AlertScanModal } from "../../components/Modals/AlertScanModal";
import { Loading } from "../../components/Loading";

import { useAuth } from "../../hooks/useAuth";
import { useScan } from "../../hooks/useScan";

import { formatDate } from "../../utils/formatDate";
import * as routes from "../../routes/constants";

import "./styles.css";

export function ConfirmRescueDetails() {
  const history = useHistory();
  const {
    dataVerifyRescueScan,
    loadingQrscan,
    postDataQrCodeScan,
    isOpenModalResponseScanQr,
    toggleModalResponseScanQr,
    dataResponseScan,
    qrscan,
  } = useScan();
  const { client } = useAuth();

  function goToBack() {
    history.goBack();
  }

  function closeModalRedirect() {
    toggleModalResponseScanQr();
    history.push(routes.SCAN);
  }

  function renderNotFoundData() {
    return (
      <div className="row">
        <div className="col s12">
          <div className="card z-depth-0">
            <div className="card-content center">
              <p id="not-found">Sem dados</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDetailsRescue() {
    console.log("qrscan", qrscan);
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div className="card-panel z-depth-0">
                <div className="section center">
                  <img
                    src="https://image.flaticon.com/icons/png/512/3649/3649803.png"
                    width="70"
                    alt=""
                  />
                  <p className="title">Tudo certo!</p>
                  <span className="subtitle grey-text text-darken-1">
                    Por favor verifique os detalhes e confirme logo após
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {dataVerifyRescueScan &&
            dataVerifyRescueScan.awards.map((award) => (
              <>
                <div className="card-panel outlined z-depth-0">
                  <p className="card-panel-title big">
                    <i className="material-icons-outlined left">visibility</i>
                    Detalhe
                  </p>

                  <p className="card-panel-title blue-text">
                    {award.premiumName}
                  </p>
                  <p className="card-panel-title">Pontos</p>
                  <span className="card-panel-subtitle">
                    {award.premiumPrice}
                  </span>
                  <p className="card-panel-title">Quantidade</p>
                  <span className="card-panel-subtitle">{award.amount}</span>
                </div>
              </>
            ))}
        </div>

        <div className="container">
          <div className="card-panel outlined amber lighten-5 z-depth-0">
            <p className="card-panel-title big">
              <i className="material-icons-outlined left">info</i>
              Mais detalhes
            </p>
            <p className="card-panel-title">Data do resgate</p>
            <span className="card-panel-subtitle">
              {dataVerifyRescueScan && dataVerifyRescueScan.createdAt
                ? formatDate(dataVerifyRescueScan.createdAt)
                : ""}
            </span>

            <p className="card-panel-title">Total de pontos</p>
            <span className="card-panel-subtitle">
              {dataVerifyRescueScan && dataVerifyRescueScan.redeemedPoints
                ? dataVerifyRescueScan.redeemedPoints
                : ""}
            </span>
          </div>
        </div>

        <div className="container">
          <div className="section">
            <button
              className="oq-btn btn-confirm white-text full-w blue waves-effect waves-light"
              onClick={postDataQrCodeScan}
            >
              Confirmar
            </button>
            <button className="oq-btn full-w waves-effect" onClick={goToBack}>
              Cancelar
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <>
          <title>
            OQ Scan -{" "}
            {`${client?.companyName ? client?.companyName : "OQ Digital"}`}
          </title>
          <link
            rel="shortcut icon"
            href={client?.favIcon ? client?.favIcon : ""}
          />
        </>
      </Helmet>
      {loadingQrscan ? (
        <Loading type="page" />
      ) : (
        <>
          <div className="navbar-fixed">
            <nav className="white z-depth-0">
              <div className="nav-wrapper">
                <div className="content-left">
                  <span className="btn-floating white z-depth-0">
                    <i
                      className="material-icons grey-text text-darken-3"
                      onClick={goToBack}
                    >
                      arrow_back
                    </i>
                  </span>
                  <span className="grey-text text-darken-3 topbar-title">
                    Confirmar resgate
                  </span>
                </div>
              </div>
            </nav>
          </div>

          {!dataVerifyRescueScan ? renderNotFoundData() : renderDetailsRescue()}
        </>
      )}

      <AlertScanModal
        isOpen={isOpenModalResponseScanQr}
        onCloseModal={toggleModalResponseScanQr}
        onCloseModalRedirect={closeModalRedirect}
        dataResponseScan={dataResponseScan}
      />
    </>
  );
}
