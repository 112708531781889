import {Helmet} from 'react-helmet-async';
import { Footer } from '../../components/Footer';
import { Header } from './components/Header';
import { InfoUser } from './components/InfoUser';
import { MetricsList } from './components/MetricsList';

import { useAuth } from '../../hooks/useAuth';
import './style.css';

export function Dashboard() {
  const { client } = useAuth();

  return (
    <>
      <Helmet>
        <title>OQ Scan - {`${client?.companyName ? client?.companyName : 'OQ Digital'}`}</title>
        <link
          rel="shortcut icon"
          href={client?.favIcon ? client?.favIcon : ''}
        />
      </Helmet>
      <Header />
      <div className="container content">
        <div className="row">
          <div style={{marginTop:20}}>
          <InfoUser />
          </div>
          <div className="col s12">
            <span id="title-label">Métricas gerais</span>
          </div>
          <MetricsList />
        </div>
      </div>
      <Footer />
    </>
  );
}
