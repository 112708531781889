import { useEffect } from 'react';
import { Loading } from '../../../../../../components/Loading';
import { useMetrics } from '../../../../../../hooks/useMetrics';

export function CardTotalPointsToReceive() {
  const {
    getTotalPointsToReceive,
    loadingResponseTotalPointsToReceive,
    dataResponseTotalPointsToReceive,
  } = useMetrics();

  useEffect(() => {
    async function loadMetricTotalQRRead() {
      await getTotalPointsToReceive();
    }

    loadMetricTotalQRRead();
  }, [getTotalPointsToReceive]);

  return (
    <div className="col s6">
      <div className="card z-depth-0 grey lighten-4">
        <div className="card-content center">
          <span className="material-icons-outlined large green-text text-accent-3">
            file_download
          </span>
          {loadingResponseTotalPointsToReceive ? (
            <div>
              <Loading type="" />
            </div>
          ) : (
            <p className="count-number">
              {dataResponseTotalPointsToReceive?.totalReceivedPoints || 0}
            </p>
          )}
          <span className="label-card truncate">Pontos a receber</span>
        </div>
      </div>
    </div>
  );
}
