import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../../../../components/Loading';
import { useMetrics } from '../../../../../../hooks/useMetrics';

import * as routes from '../../../../../../routes/constants';

export function CardTotalQRRead() {
  const history = useHistory();

  const {
    getTotalQRRead,
    loadingResponseTotalQRRead,
    dataResponseTotalQRRead,
  } = useMetrics();

  useEffect(() => {
    async function loadMetricTotalQRRead() {
      await getTotalQRRead();
    }

    loadMetricTotalQRRead();
  }, [getTotalQRRead]);

  function goToPageDetailsTotalQRRead() {
    history.push(`${routes.DETAILS}/totalQRRead`);
  }

  return (
    <div onClick={goToPageDetailsTotalQRRead} className="col s6">
      <div className="card z-depth-0 grey lighten-4 waves-effect" style={{width: '100%'}}>
        <div className="card-content center">
          <span className="material-icons-outlined large">qr_code</span>
          {loadingResponseTotalQRRead ? (
            <div>
              <Loading type="" />
            </div>
          ) : (
            <p className="count-number">
              {dataResponseTotalQRRead?.amountUserQRCodes || 0}
            </p>
          )}
          <span className="label-card truncate">Total QR lidos</span>
        </div>
      </div>
    </div>
  );
}
