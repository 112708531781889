import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../hooks/useAuth';
import { useMetrics } from '../../hooks/useMetrics';
import { formatDate } from '../../utils/formatDate';
import { formatNumberWithComas } from '../../utils/formatNumberWithComas';
import './style.css';

export function Details(props: any) {
  const history = useHistory();
  const { client } = useAuth();

  const {
    getTotalQRRead,
    dataResponseTotalQRRead,
    loadingResponseTotalQRRead,
    getTotalQRValidated,
    dataResponseTotalQRValidated,
    loadingResponseTotalQRValidated,
  } = useMetrics();

  const [typelist, setTypelist] = useState();
  const [titlelist, setTitlelist] = useState('');

  useEffect(() => {
    const typelistParams = props.match.params;

    setTypelist(typelistParams.typelist);

    if (typelistParams.typelist === 'totalQRRead')
      return setTitlelist('QR Lidos');
    if (typelistParams.typelist === 'totalQRValidated')
      return setTitlelist('QR Validados');
  }, [props.match.params]);

  useEffect(() => {
    async function getListMetrics() {
      if (typelist === 'totalQRRead') return getTotalQRRead();
      if (typelist === 'totalQRValidated') return getTotalQRValidated();
    }
    getListMetrics();
  }, [getTotalQRRead, getTotalQRValidated, typelist]);

  function backPage() {
    history.goBack();
  }

  function renderList() {
    if (typelist === 'totalQRRead') {
      if (loadingResponseTotalQRRead) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loading type="" />
          </div>
        );
      }
      return (
        <>
          {dataResponseTotalQRRead?.userQRCodes.map((qrRead) => (
            <li key={qrRead.uid} className="collection-item avatar">
              <i className="material-icons grey lighten-3 circle grey-text text-darken-3">
                qr_code
              </i>
              <span className="title">Data: {formatDate(qrRead.createdAt)}</span><br />
              <span className="tiny-text-label grey-text text-lighten-1">{qrRead.uid}</span>
              <span className="green-text secondary-content tiny-text">
                {`${formatNumberWithComas(
                  Number(qrRead.redeemedPoints.toFixed(0))
                )} pontos`}
              </span>
            </li>
          ))}
        </>
      );
    }
    if (typelist === 'totalQRValidated') {
      if (loadingResponseTotalQRValidated) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loading type="" />
          </div>
        );
      }
      return (
        <>
          {dataResponseTotalQRValidated?.userQRCodesValidated.map(
            (qrValidate) => (
              <li key={qrValidate.uid} className="collection-item avatar">
                <i className="material-icons grey lighten-3 circle grey-text text-darken-3">
                  qr_code
                </i>
                <span className="title">Data: {formatDate(qrValidate.createdAt)}</span><br />
                <span className="tiny-text-label grey-text text-lighten-1">{qrValidate.uid}</span>
                <span className="green-text secondary-content tiny-text">
                  {`${formatNumberWithComas(
                    Number(qrValidate.redeemedPoints.toFixed(0))
                  )} pontos`}
                </span>
              </li>
            )
          )}
        </>
      );
    }
  }

  return (
    <>
      <div className="navbar-fixed">
        <Helmet>
          <title>
            {`${client?.companyName ? client?.companyName : 'OQ Digital'}`} - OQ
            Scan
          </title>
          <link
            rel="shortcut icon"
            href={client?.favIcon ? client?.favIcon : ''}
          />
        </Helmet>
        <nav className="white z-depth-0">
          <div className="nav-wrapper">
            <div className="content-left">
              <span className="btn-floating white z-depth-0">
                <i
                  onClick={backPage}
                  className="material-icons grey-text text-darken-3"
                >
                  arrow_back
                </i>
              </span>
              <span className="grey-text text-darken-3 topbar-title">
                {`${titlelist || 'Detalhes'}`}
              </span>
            </div>
            <div className="content-right"></div>
          </div>
        </nav>
      </div>
      <div className="container">
        <div className="row">
          <ul className="collection">{renderList()}</ul>
        </div>
      </div>
    </>
  );
}
