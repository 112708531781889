import { db } from './firebase';

export const doCreateUser = (id: string, username: string, email: string) => {
  const nowEpoch = new Date();
  return db.ref(`Admins/${id}/`).set({
    username,
    email,
    uid: id,
    createdDateEpoch: nowEpoch.getTime(),
  });
};

export const getSigninClient = (userId: string) => {
  return db.ref(`Users/${userId}`).once('value');
};
export const getSigninClientWithUser = (clientId: string | undefined) => {
  return db.ref(`Clients/${clientId}`).once('value');
};

// export const SigninClientRef = (userId: string) => {
//   return db.ref(`Clients/${userId}`);
// };

export const onceGetAdmins = (userId: string) =>
  db.ref(`Admins/${userId}`).once('value');

export const getAllUsers = () => db.ref('Users');

export const refNode = (nodeRef: string) => db.ref(nodeRef);
