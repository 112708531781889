import { useEffect } from 'react';
import { Loading } from '../../../../../../components/Loading';
import { useMetrics } from '../../../../../../hooks/useMetrics';

export function CardTotalPaymentPoints() {
  const {
    getTotalPaymentPoints,
    loadingResponseTotalPaymentPoints,
    dataResponseTotalPaymentPoints,
  } = useMetrics();

  useEffect(() => {
    async function loadMetricTotalPaymentPoints() {
      await getTotalPaymentPoints();
    }

    loadMetricTotalPaymentPoints();
  }, [getTotalPaymentPoints]);

  return (
    <div className="col s6">
      <div className="card z-depth-0 grey lighten-4">
        <div className="card-content center">
          <span className="material-icons-outlined large cyan-text text-darken-3">
            trending_up
          </span>
          {loadingResponseTotalPaymentPoints ? (
            <div>
              <Loading type="" />
            </div>
          ) : (
            <p className="count-number">
              {dataResponseTotalPaymentPoints?.totalRedeemedPoints || 0}
            </p>
          )}
          <span className="label-card truncate">Pontos pagos</span>
        </div>
      </div>
    </div>
  );
}
