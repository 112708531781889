import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useScan } from '../../hooks/useScan';
import { QrContainer } from './components/QrContainer';

import './style.css';

export function ScanQr() {
  const history = useHistory();
  const { openCameraQrCode, isOpenQrCode } = useScan();
  const { client } = useAuth();

  function backPage() {
    history.goBack();
  }

  return (
    <>
      <Helmet>
        <title>OQ Scan - {`${client?.companyName ? client?.companyName : 'OQ Digital'}`}</title>
        <link
          rel="shortcut icon"
          href={client?.favIcon ? client?.favIcon : ''}
        />
      </Helmet>

      <div className="navbar-fixed">
        <nav className="white z-depth-0">
          <div className="nav-wrapper">
            <div className="content-left">
              <span className="btn-floating white z-depth-0">
                <i
                  onClick={backPage}
                  className="material-icons grey-text text-darken-3"
                >
                  arrow_back
                </i>
              </span>
              <span className="grey-text text-darken-3 topbar-title">
                Leitor QR
              </span>
            </div>
          </div>
        </nav>
      </div>

      <div className="wrapper-container">

        <div className="container">
          <div id="wrapper-view">
            {isOpenQrCode ? (
              <QrContainer />
            ) : (
              <>
                <img src="https://image.flaticon.com/icons/png/512/2313/2313110.png" id="qr-logo" alt="" />
              </>
            )}
          </div>
        </div>

        <div id="action-qr" className="center">
          <div className="container">

            <div className="card grey lighten-4 z-depth-0">
              <div className="card-content center">
                <span className="card-title">Fácil e prático</span>
                <p>
                  Leia de forma fácil e prática o código aproximando sua
                  câmera.
                </p>
              </div>
            </div>

            <button
              onClick={openCameraQrCode}
              className={isOpenQrCode ? 'btn-large blue-text blue lighten-5 custom-btn custom-btn-full z-depth-0 waves-effect' : 'btn-large blue custom-btn custom-btn-full z-depth-0 waves-effect waves-light'}>
              {isOpenQrCode ? 'Cancelar' : 'Ler QR agora'}
            </button>
          </div>
        </div>

      </div>
    </>
  );
}
