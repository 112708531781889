import React, { createContext, ReactNode, useCallback, useState } from 'react';
import { api } from '../services/api';
import { auth } from '../firebase';
import { useAuth } from '../hooks/useAuth';

interface MetricContextProps {
  getTotalQRRead(): Promise<void>;
  loadingResponseTotalQRRead: boolean;
  dataResponseTotalQRRead: DataResponseTotalQRRead | undefined;
  getTotalQRValidated(): Promise<void>;
  loadingResponseTotalQRValidated: boolean;
  dataResponseTotalQRValidated: DataResponseTotalQRValidated | undefined;
  getTotalPaymentPoints(): Promise<void>;
  loadingResponseTotalPaymentPoints: boolean;
  dataResponseTotalPaymentPoints: DataResponseTotalPaymentPoints | undefined;
  getTotalPointsToReceive(): Promise<void>;
  loadingResponseTotalPointsToReceive: boolean;
  dataResponseTotalPointsToReceive:
    | DataResponseTotalPointsToReceive
    | undefined;
}

interface UserQRCodesProps {
  clientId: string;
  createdAt: number;
  playerId: string;
  rescueId: string;
  uid: string;
  userId: string;
  validated: boolean;
  redeemedPoints: number;
}

interface DataResponseTotalQRRead {
  amountUserQRCodes: number;
  userQRCodes: UserQRCodesProps[];
}

interface DataResponseTotalQRValidated {
  amountUserQRCodesValidated: number;
  userQRCodesValidated: UserQRCodesProps[];
}

interface RescueProps {
  clientId: string;
  createdAt: number;
  id: string;
  lastUpdate: number;
  playerId: string;
  redeemedPoints: number;
  status: string;
  totalPaymentValue: number;
  validated: boolean;
}

interface DataResponseTotalPaymentPoints {
  totalRedeemedPoints: number;
  arrRescue: RescueProps[];
}
interface DataResponseTotalPointsToReceive {
  totalReceivedPoints: number;
}

export const metricContext = createContext({} as MetricContextProps);

interface MetricContextProviderProps {
  children: ReactNode;
}

export function MetricContextProvider(props: MetricContextProviderProps) {
  const { client, authenticatedUser } = useAuth();

  const [loadingResponseTotalQRRead, setLoadingResponseTotalQRRead] =
    useState(true);
  const [dataResponseTotalQRRead, setDataResponseTotalQRRead] =
    useState<DataResponseTotalQRRead>();

  const [loadingResponseTotalQRValidated, setLoadingResponseTotalQRValidated] =
    useState(true);
  const [dataResponseTotalQRValidated, setDataResponseTotalQRValidated] =
    useState<DataResponseTotalQRValidated>();

  const [
    loadingResponseTotalPaymentPoints,
    setLoadingResponseTotalPaymentPoints,
  ] = useState(true);
  const [dataResponseTotalPaymentPoints, setDataResponseTotalPaymentPoints] =
    useState<DataResponseTotalPaymentPoints>();

  const [
    loadingResponseTotalPointsToReceive,
    setLoadingResponseTotalPointsToReceive,
  ] = useState(true);
  const [
    dataResponseTotalPointsToReceive,
    setDataResponseTotalPointsToReceive,
  ] = useState<DataResponseTotalPointsToReceive>();

  const getTotalQRRead = useCallback(async () => {
    if (client && client.uid && authenticatedUser?.uid) {
      try {
        setLoadingResponseTotalQRRead(true);
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          setLoadingResponseTotalQRRead(false);
          // Notify('Falha na autenticação', 'error');
          alert('Falha na autenticação');
          return;
        }

        const parameters = {
          searchFunctionality: 'getTotalQRRead',
          userType: 'appScan',
          clientId: client && client.uid,
          userId: authenticatedUser?.uid,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        const { data } = await api.post(`/appScan`, parameters, requestConfig);
        setLoadingResponseTotalQRRead(false);

        if (data) {
          setDataResponseTotalQRRead(data.data);
          return;
        }
      } catch (error) {
        setLoadingResponseTotalQRRead(false);
        console.log('error getTotalQRRead-->', error);
      }
    }
  }, [authenticatedUser?.uid, client]);

  const getTotalQRValidated = useCallback(async () => {
    if (client && client.uid && authenticatedUser?.uid) {
      // setTimeout(() => {}, 10000)
      try {
        setLoadingResponseTotalQRValidated(true);
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          setLoadingResponseTotalQRValidated(false);
          // Notify('Falha na autenticação', 'error');
          alert('Falha na autenticação');
          return;
        }

        const parameters = {
          searchFunctionality: 'getTotalQRValidated',
          userType: 'appScan',
          clientId: client && client.uid,
          userId: authenticatedUser?.uid,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        const { data } = await api.post(`/appScan`, parameters, requestConfig);
        setLoadingResponseTotalQRValidated(false);

        if (data) {
          setDataResponseTotalQRValidated(data.data);
          return;
        }
      } catch (error) {
        setLoadingResponseTotalQRValidated(false);
        console.log('error getTotalQRValidated-->', error);
      }
    }
  }, [authenticatedUser?.uid, client]);

  const getTotalPaymentPoints = useCallback(async () => {
    if (client && client.uid && authenticatedUser?.uid) {
      try {
        setLoadingResponseTotalPaymentPoints(true);
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          setLoadingResponseTotalPaymentPoints(false);
          // Notify('Falha na autenticação', 'error');
          alert('Falha na autenticação');
          return;
        }

        const parameters = {
          searchFunctionality: 'getTotalPaymentPoints',
          userType: 'appScan',
          clientId: client && client.uid,
          userId: authenticatedUser?.uid,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        const { data } = await api.post(`/appScan`, parameters, requestConfig);
        setLoadingResponseTotalPaymentPoints(false);
        // console.log('response getTotalPaymentPoints-->', data);

        if (data) {
          setDataResponseTotalPaymentPoints(data.data);
          return;
        }
      } catch (error) {
        setLoadingResponseTotalPaymentPoints(false);
        console.log('error getTotalQRValidated-->', error);
      }
    }
  }, [authenticatedUser?.uid, client]);

  const getTotalPointsToReceive = useCallback(async () => {
    if (client && client.uid && authenticatedUser?.uid) {
      try {
        setLoadingResponseTotalPointsToReceive(true);
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          setLoadingResponseTotalPointsToReceive(false);
          // Notify('Falha na autenticação', 'error');
          alert('Falha na autenticação');
          return;
        }

        const parameters = {
          searchFunctionality: 'getTotalPointsToReceive',
          userType: 'appScan',
          clientId: client && client.uid,
          userId: authenticatedUser?.uid,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        const { data } = await api.post(`/appScan`, parameters, requestConfig);
        setLoadingResponseTotalPointsToReceive(false);
        // console.log('response getTotalPointsToReceive-->', data);

        if (data) {
          setDataResponseTotalPointsToReceive(data.data);
          return;
        }
      } catch (error) {
        setLoadingResponseTotalPointsToReceive(false);
        console.log('error getTotalQRValidated-->', error);
      }
    }
  }, [authenticatedUser?.uid, client]);

  return (
    <metricContext.Provider
      value={{
        getTotalQRRead,
        dataResponseTotalQRRead,
        loadingResponseTotalQRRead,
        getTotalQRValidated,
        dataResponseTotalQRValidated,
        loadingResponseTotalQRValidated,
        getTotalPaymentPoints,
        dataResponseTotalPaymentPoints,
        loadingResponseTotalPaymentPoints,
        getTotalPointsToReceive,
        loadingResponseTotalPointsToReceive,
        dataResponseTotalPointsToReceive,
      }}
    >
      {props.children}
    </metricContext.Provider>
  );
}
