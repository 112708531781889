import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export function NotFound() {

  const history = useHistory();
  const { client } = useAuth();

  function backPage() {
    history.goBack();
  }

  return (
    <div>
      <Helmet>
        <title>OQ Scan - {`${client?.companyName ? client?.companyName : 'OQ Digital'}`}</title>
        <link
          rel="shortcut icon"
          href={client?.favIcon ? client?.favIcon : ''}
        />
      </Helmet>
      <div className="navbar-fixed">
        <nav className="white z-depth-0">
          <div className="nav-wrapper">
            <div className="content-left" style={{marginLeft: '20px'}}>
              <span className="grey-text text-darken-3 topbar-title">
                404 Not Found
              </span>
            </div>
          </div>
        </nav>
      </div>

      <div className="wrapper-container">

        <div className="container">
          <div id="wrapper-view">
            <img src="https://image.flaticon.com/icons/png/512/5335/5335794.png" id="qr-logo" alt="" />
          </div>
        </div>

        <div id="action-qr" className="center">
          <div className="container">

            <div className="card grey lighten-4 z-depth-0">
              <div className="card-content center">
                <span className="card-title">Ops, nada por aqui!</span>
                <p>Não existe nenhuma função por aqui, volte para o app...</p>
              </div>
            </div>

            <button
              onClick={backPage}
              className="btn-large blue custom-btn custom-btn-full z-depth-0 waves-effect waves-light">Voltar</button>
          </div>
        </div>

      </div>
    </div>
  );
}
