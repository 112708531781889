import { CardTotalPaymentPoints } from './components/CardTotalPaymentPoints';
import { CardTotalPointsToReceive } from './components/CardTotalPointsToReceive';
import { CardTotalQRRead } from './components/CardTotalQRRead';
import { CardTotalQRValidated } from './components/CardTotalQRValidated';

export function MetricsList() {
  return (
    <>
      <CardTotalQRRead />
      <CardTotalPointsToReceive />
      <CardTotalQRValidated />
      <CardTotalPaymentPoints />
    </>
  );
}
