import { useState } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';

import { useScan } from '../../../../hooks/useScan';
import { Loading } from '../../../../components/Loading';

import * as routes from '../../../../routes/constants';

import './style.css';

export function QrContainer() {
  const history = useHistory();

  const {
    verifyRescueQrCodeScan,
    loadingQrscan,
    setDataVerifyRescueScan,
    openCameraQrCode,
    setQrscan,
  } = useScan();

  // const [qrscan, setQrscan] = useState('');
  const [, setStatusQrscan] = useState(
    'Centralize o QR Code na borda vermelha'
  );

  async function handleScan(data: string | null) {
    if (data) {
      setQrscan(data);
      setStatusQrscan('Aguarde');
      const response = await verifyRescueQrCodeScan(data);
      // console.log('responsee-->', response);
      if (response) {
        setDataVerifyRescueScan(response);
        openCameraQrCode();
        history.push(routes.CONFIRM_RESCUE_DETAILS);
        return;
      } else {
        return alert('Resgate não encontrado');
      }
    } else {
      setStatusQrscan('Centralize o QR Code na borda vermelha');
    }
  }

  function handleError(err: any) {
    console.error('erro ao scanear', err);
  }

  function renderLoading() {
    return (
      <div className="loading-center">
        <Loading type="" />
      </div>
    );
  }

  return (
    <>
      {loadingQrscan ? (
        renderLoading()
      ) : (
        <QrReader
          facingMode="environment"
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{
            width: '300px',
            height: '300px',
          }}
        />
      )}
    </>
  );
}
