import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  FormEvent,
} from 'react';

import { api } from '../services/api';
import { auth, db, firebase } from '../firebase';

interface ClientDataProps {
  primaryColor: string;
  secondaryColor: string;
  systemFont: string;
  favIcon: string;
  logoURL: string;
  loginImage: string;
  targetPublic: string;
  uid: string;
  loginEmail: string;
  isTeamCompetition: string;
  contactEmail: string;
  clientUrl: string;
  companyName: string;
  accountableEmail: string;
  accountableName: string;
}

interface UserAuthProps {
  clientId: string;
  cpf: string;
  createdAt: number;
  email: string;
  master: boolean;
  name: string;
  uid: string;
  updatedAt: number;
  avatar: string;
}

interface AuthContextProps {
  client: ClientDataProps | undefined;
  loginUser: (
    event: FormEvent,
    email: string,
    password: string,
    clientId: string | undefined
  ) => Promise<UserAuthProps | undefined>;
  user: UserAuthProps | undefined;
  statusLogin: string;
  loadingClientData: boolean;
  loadingLogin: boolean;
  authenticatedUser: firebase.firebase.UserInfo | null;
  loadingAuthenticatedUser: boolean;
  getClientDataByAlias: (clientAlias: string, isUrl: boolean) => Promise<void>;
  verifyOnAuthStateChanged: () => void;
}

export const authContext = createContext({} as AuthContextProps);

interface AuthContextProviderProps {
  children: ReactNode;
}

export function AuthContextProvider(props: AuthContextProviderProps) {
  const [loadingClientData, setLoadingClientData] = useState(false);
  const [client, setClient] = useState<ClientDataProps | undefined>();

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [statusLogin, setStatusLogin] = useState('Login');
  const [user, setUser] = useState<UserAuthProps | undefined>();

  const [authenticatedUser, setAuthenticatedUser] = useState(() => {
    const user = firebase.auth.currentUser;

    return user;
  });
  const [loadingAuthenticatedUser, setLoadingAuthenticatedUser] =
    useState(true);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setAuthenticatedUser(firebaseUser);
        setLoadingAuthenticatedUser(false);
        const userRef = await (
          await db.getSigninClient(firebaseUser.uid)
        ).val();
        if (userRef) {
          console.log('userr', userRef);
          setUser(userRef);
          const clientRef = await (
            await db.getSigninClientWithUser(userRef.clientId)
          ).val();
          if (clientRef) {
            console.log('clientt', clientRef);
            setClient(clientRef);
          }
        }
        return;
      } else {
        setAuthenticatedUser(null);
        setLoadingAuthenticatedUser(false);
      }
    });
  }, []);

  function verifyOnAuthStateChanged() {
    firebase.auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setAuthenticatedUser(firebaseUser);
        setLoadingAuthenticatedUser(false);
        const userRef = await (
          await db.getSigninClient(firebaseUser.uid)
        ).val();
        if (userRef) {
          // console.log('userr', userRef);
          setUser(userRef);
          const clientRef = await (
            await db.getSigninClientWithUser(userRef.clientId)
          ).val();
          if (clientRef) {
            // console.log('clientt', clientRef);
            setClient(clientRef);
          }
        }
        return;
      } else {
        setAuthenticatedUser(null);
        setLoadingAuthenticatedUser(false);
      }
    });
  }

  async function getClientDataByAlias(clientAlias: string, isUrl: boolean) {
    setLoadingClientData(true);

    const parameters = {
      searchFunctionality: 'getClientByAlias',
      userType: 'appScan',
      clientAlias,
      isUrl,
    };

    try {
      const { data } = await api.post(`/appScan`, parameters);
      // console.log('clientt-->', data);
      setLoadingClientData(false);
      if (data.success) {
        setClient(data.data);
        return;
      } else {
        console.log('cliente não encontrado-->', data);
        setClient(undefined);
        alert('Cliente não encontrado');
      }
    } catch (error) {
      setLoadingClientData(false);
      setClient(undefined);
      console.log('erro getClientDataByAlias', error);
      alert('erro getClientDataByAlias');
    }
  }

  async function loginUser(
    event: FormEvent,
    email: string,
    password: string,
    clientId: string | undefined
  ) {
    return new Promise<UserAuthProps | undefined>(async (resolve, reject) => {
      event.preventDefault();

      setLoadingLogin(true);
      setStatusLogin('Entrando...');

      try {
        const responseUserAuth = await auth.doSignInWithEmailAndPassword(
          email,
          password,
          clientId
        );
        // console.log('responseUserAuth-->', responseUserAuth);

        if (responseUserAuth.success) {
          const client = await db
            .getSigninClientWithUser(clientId)
            .then((snapClient) => snapClient.val());
          setStatusLogin('Login');

          if (client) {
            if (!client.active) {
              setLoadingLogin(false);
              alert('Email ou senha incorreto.');
              return;
            }
            setUser(responseUserAuth.data);
            resolve(responseUserAuth.data);
          } else {
            setLoadingLogin(false);
            alert('Acesso exclusivo para clientes.');
          }
        } else {
          setLoadingLogin(false);
          console.log('erroo-->', responseUserAuth);
        }
      } catch (error: any) {
        setLoadingLogin(false);
        setStatusLogin('Login');
        console.log('loginUser-->', error);
        let errorMsg = '';

        if (error && error.code === 'auth/wrong-password') {
          errorMsg = 'E-mail ou senha incorretos.';
        } else if (
          error &&
          error.code === 'auth/account-exists-with-different-credential'
        ) {
          errorMsg = 'Já existe uma conta com o e-mail informado.';
        } else if (
          error &&
          error.message ===
            'Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações.'
        ) {
          errorMsg = 'E-mail não cadastrado para este cliente.';
        } else {
          errorMsg = 'E-mail ou senha incorretos.';
        }

        alert(errorMsg);
      }
    });
  }

  return (
    <authContext.Provider
      value={{
        client,
        loginUser,
        user,
        loadingClientData,
        loadingLogin,
        statusLogin,
        authenticatedUser,
        loadingAuthenticatedUser,
        getClientDataByAlias,
        verifyOnAuthStateChanged,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}
