import React, { createContext, ReactNode, useCallback, useState } from 'react';
import { api } from '../services/api';
import { auth } from '../firebase';
import { useAuth } from '../hooks/useAuth';

export interface DataResponseScanProps {
  message: string;
  success: boolean;
}

interface AwardsProps {
  premiumName: string;
  premiumPrice: number;
  amount: number;
}

export interface DataVerifyRescueScanProps {
  awards: AwardsProps[];
  playerName: string;
  playerEmail: string;
  redeemedPoints: number;
  createdAt: number;
  id: string;
}

interface ScanContextProps {
  toggleModalResponseScanQr: () => void;
  loadingQrscan: boolean;
  isOpenModalResponseScanQr: boolean;
  isOpenQrCode: boolean;
  openCameraQrCode: () => void;
  dataResponseScan: DataResponseScanProps | undefined;
  verifyRescueQrCodeScan(data: string): Promise<DataVerifyRescueScanProps>;
  dataVerifyRescueScan: DataVerifyRescueScanProps | undefined;
  setDataVerifyRescueScan: React.Dispatch<
    React.SetStateAction<DataVerifyRescueScanProps | undefined>
  >;
  postDataQrCodeScan(): Promise<void>;
  setQrscan: React.Dispatch<React.SetStateAction<string>>;
  qrscan: string;
}

export const scanContext = createContext({} as ScanContextProps);

interface ScanContextProviderProps {
  children: ReactNode;
}

export function ScanContextProvider(props: ScanContextProviderProps) {
  const { client, authenticatedUser } = useAuth();

  const [qrscan, setQrscan] = useState('');

  const [isOpenQrCode, setIsOpenQrCode] = useState(false);

  const [loadingQrscan, setLoadingQrscan] = useState(false);

  const [isOpenModalResponseScanQr, setIsOpenModalResponseScanQr] =
    useState(false);

  const [dataResponseScan, setDataResponseScan] =
    useState<DataResponseScanProps>();

  const [dataVerifyRescueScan, setDataVerifyRescueScan] =
    useState<DataVerifyRescueScanProps>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function toggleModalResponseScanQr() {
    setIsOpenModalResponseScanQr(!isOpenModalResponseScanQr);
  }

  function openCameraQrCode() {
    setIsOpenQrCode(!isOpenQrCode);
  }

  // console.log('testesteste', qrscan);

  const postDataQrCodeScan = useCallback(async () => {
    console.log('aaaaaaaaaaaaaa', qrscan);
    try {
      if (qrscan) {
        setLoadingQrscan(true);
        const authToken = await auth.getAuthUserToken();

        if (!authToken) {
          setLoadingQrscan(false);
          // Notify('Falha na autenticação', 'error');
          alert('Falha na autenticação');
          return;
        }

        console.log('aq', dataVerifyRescueScan);
        const parameters = {
          searchFunctionality: 'postDataQrCodeScan',
          userType: 'appScan',
          clientId: client && client.uid,
          userId: authenticatedUser?.uid,
          rescueId: qrscan,
        };

        const requestConfig = {
          headers: { Authorization: authToken },
        };

        const { data } = await api.post(`/appScan`, parameters, requestConfig);
        setLoadingQrscan(false);

        // console.log('responseee-->', data);
        if (data) {
          setDataResponseScan(data);
          // openCameraQrCode();
          toggleModalResponseScanQr();
          return;
        }
      }
    } catch (error) {
      setLoadingQrscan(false);
      // openCameraQrCode();
      console.log('error postDataQrCodeScan-->', error);
    }
  }, [
    authenticatedUser?.uid,
    client,
    dataVerifyRescueScan,
    qrscan,
    toggleModalResponseScanQr,
  ]);

  // async function postDataQrCodeScan(qrscan: string) {
  //   console.log('aaaaaaaaaaaaaa', qrscan);
  //   try {
  //     if (qrscan) {
  //       setLoadingQrscan(true);
  //       const authToken = await auth.getAuthUserToken();

  //       if (!authToken) {
  //         setLoadingQrscan(false);
  //         // Notify('Falha na autenticação', 'error');
  //         alert('Falha na autenticação');
  //         return;
  //       }

  //       console.log('aq', dataVerifyRescueScan);
  //       const parameters = {
  //         searchFunctionality: 'postDataQrCodeScan',
  //         userType: 'appScan',
  //         clientId: client && client.uid,
  //         userId: authenticatedUser?.uid,
  //         rescueId: qrscan,
  //       };

  //       const requestConfig = {
  //         headers: { Authorization: authToken },
  //       };

  //       const { data } = await api.post(`/appScan`, parameters, requestConfig);
  //       setLoadingQrscan(false);

  //       // console.log('responseee-->', data);
  //       if (data) {
  //         setDataResponseScan(data);
  //         // openCameraQrCode();
  //         toggleModalResponseScanQr();
  //         return;
  //       }
  //     }
  //   } catch (error) {
  //     setLoadingQrscan(false);
  //     // openCameraQrCode();
  //     console.log('error postDataQrCodeScan-->', error);
  //   }
  // }

  const verifyRescueQrCodeScan = useCallback(
    async (qrcodeScan: string) => {
      return new Promise<DataVerifyRescueScanProps>(async (resolve, reject) => {
        try {
          console.log('qrscan verifyRescueQrCodeScan', qrcodeScan);
          setLoadingQrscan(true);
          const authToken = await auth.getAuthUserToken();

          if (!authToken) {
            setLoadingQrscan(false);
            // Notify('Falha na autenticação', 'error');
            alert('Falha na autenticação');
            return;
          }

          const parameters = {
            searchFunctionality: 'verifyRescueQrCodeScan',
            userType: 'appScan',
            clientId: client && client.uid,
            userId: authenticatedUser?.uid,
            rescueId: qrcodeScan,
          };

          const requestConfig = {
            headers: { Authorization: authToken },
          };

          const { data } = await api.post(
            `/appScan`,
            parameters,
            requestConfig
          );
          setLoadingQrscan(false);

          if (data) {
            openCameraQrCode();
            resolve(data.data);
            return;
          }
        } catch (error) {
          setLoadingQrscan(false);
          openCameraQrCode();
          reject(error);
          console.log('error postDataQrCodeScan-->', error);
        }
      });
    },
    [authenticatedUser?.uid, client, openCameraQrCode, qrscan]
  );

  // async function verifyRescueQrCodeScan() {
  //   return new Promise<DataVerifyRescueScanProps>(async (resolve, reject) => {
  //     try {
  //       console.log('qrscan verifyRescueQrCodeScan', qrscan);
  //       setLoadingQrscan(true);
  //       const authToken = await auth.getAuthUserToken();

  //       if (!authToken) {
  //         setLoadingQrscan(false);
  //         // Notify('Falha na autenticação', 'error');
  //         alert('Falha na autenticação');
  //         return;
  //       }

  //       const parameters = {
  //         searchFunctionality: 'verifyRescueQrCodeScan',
  //         userType: 'appScan',
  //         clientId: client && client.uid,
  //         userId: authenticatedUser?.uid,
  //         rescueId: qrscan,
  //       };

  //       const requestConfig = {
  //         headers: { Authorization: authToken },
  //       };

  //       const { data } = await api.post(`/appScan`, parameters, requestConfig);
  //       setLoadingQrscan(false);

  //       if (data) {
  //         openCameraQrCode();
  //         resolve(data.data);
  //         return;
  //       }
  //     } catch (error) {
  //       setLoadingQrscan(false);
  //       openCameraQrCode();
  //       reject(error);
  //       console.log('error postDataQrCodeScan-->', error);
  //     }
  //   });
  // }

  return (
    <scanContext.Provider
      value={{
        toggleModalResponseScanQr,
        loadingQrscan,
        isOpenModalResponseScanQr,
        isOpenQrCode,
        openCameraQrCode,
        dataResponseScan,
        dataVerifyRescueScan,
        verifyRescueQrCodeScan,
        setDataVerifyRescueScan,
        postDataQrCodeScan,
        qrscan,
        setQrscan,
      }}
    >
      {props.children}
    </scanContext.Provider>
  );
}
