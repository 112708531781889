import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../../../../components/Loading';
import { useMetrics } from '../../../../../../hooks/useMetrics';
import * as routes from '../../../../../../routes/constants';

export function CardTotalQRValidated() {
  const history = useHistory();
  const {
    getTotalQRValidated,
    loadingResponseTotalQRValidated,
    dataResponseTotalQRValidated,
  } = useMetrics();

  useEffect(() => {
    async function loadMetricTotalQRValidated() {
      await getTotalQRValidated();
    }

    loadMetricTotalQRValidated();
  }, [getTotalQRValidated]);

  function goToPageDetailsTotalQValidated() {
    history.push(`${routes.DETAILS}/totalQRValidated`);
  }

  return (
    <div onClick={goToPageDetailsTotalQValidated} className="col s6 ">
      <div className="card z-depth-0 grey lighten-4 waves-effect" style={{width: '100%'}}>
        <div className="card-content center">
          <span className="material-icons-outlined large orange-text text-accent-4">
            task_alt
          </span>
          {loadingResponseTotalQRValidated ? (
            <div>
              <Loading type="" />
            </div>
          ) : (
            <p className="count-number">
              {dataResponseTotalQRValidated?.amountUserQRCodesValidated || 0}
            </p>
          )}
          <span className="label-card truncate">Total validados</span>
        </div>
      </div>
    </div>
  );
}
