import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import defaultIcon from '../../../assets/icons/default-icon.png';
import IconSuccessScanner from '../../../assets/svgs/checked.svg';
import IconErrorScanner from '../../../assets/svgs/error.svg';
import { DataResponseScanProps } from '../../../contexts/ScanContext';
import './styles.css';

interface AlertScanModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onCloseModalRedirect: () => void;
  dataResponseScan: DataResponseScanProps | undefined;
}

export function AlertScanModal({
  isOpen,
  onCloseModal,
  dataResponseScan,
  onCloseModalRedirect,
}: AlertScanModalProps) {
  // const props = { isOpen, onCloseModal, dataResponseScan };
  // console.log('propss-->', props);

  function renderModalSuccess() {
    return (
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          modalContainer: 'oq-container',
          closeButton: 'btn btn-floating z-depth-0 transparent waves-effect',
        }}
        showCloseIcon={false}
      >
        <div className="oq-wrapper-contents oq-center">
          <img
            className="oq-alert-icon"
            src={IconSuccessScanner ? IconSuccessScanner : defaultIcon}
            alt="Ícone"
          />
          <h1 className="title-alert">Sucesso</h1>
          <p className="content-alert">Seu código foi lido com sucesso !</p>
        </div>

        <div className="oq-group-horizontal">
          <button
            className="oq-btn btn-confirm green darken-2 waves-effect waves-light"
            onClick={onCloseModalRedirect}
          >
            Tudo Certo
          </button>
        </div>
      </Modal>
    );
  }

  function renderModalError() {
    return (
      <Modal
        showCloseIcon={false}
        open={isOpen}
        onClose={onCloseModal}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          modalContainer: 'oq-container',
          closeButton: 'btn btn-floating z-depth-0 transparent waves-effect',
        }}
      >
        <div className="oq-wrapper-contents oq-center">
          <img
            className="oq-alert-icon"
            src={IconErrorScanner ? IconErrorScanner : defaultIcon}
            alt="Ícone"
          />
          <h1 className="title-alert">Oops!</h1>
          <p className="content-alert">
            {dataResponseScan && dataResponseScan.message}!
          </p>
        </div>

        <div className="oq-group-horizontal">
          <button
            className="oq-btn btn-confirm red darken-2 waves-effect waves-light"
            onClick={onCloseModal}
          >
            Tentar novamente
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <>
      {dataResponseScan &&
      // dataResponseScan.success &&
      dataResponseScan.success === true
        ? renderModalSuccess()
        : renderModalError()}
    </>
  );
}
