import { useAuth } from '../../../../hooks/useAuth';

export function InfoUser() {
  const { user } = useAuth();
  return (
    <div className="col s12">
      <div className="card z-depth-0 grey lighten-4">
        <div className="card-content center">
          <img
            // src="https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
            src={`${
              user?.avatar
                ? user.avatar
                : 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
            }`}
            width="100"
            alt=""
            className="circle"
          />
          <br />
          <br />
          <p id="username">{user?.name ? user?.name : ''}</p>
          <p id="user-email">{user?.email ? user?.email : ''}</p>
        </div>
      </div>
    </div>
  );
}
