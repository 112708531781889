import { Preloader } from 'react-materialize';

import './style.css';

interface LoadingProps {
  type?: string;
}

export function Loading({ type }: LoadingProps) {
  return (
    <>
      {type && type === 'page' ? (
        <div className="center-loading ">
          <Preloader
            className="spinner-color"
            active
            flashing={false}
            size="medium"
          />
          <span style={{ marginTop: '1.2rem' }}>Carregando...</span>
        </div>
      ) : (
        <Preloader
          className="spinner-color"
          active
          flashing={false}
          size="medium"
        />
      )}
    </>
  );
}
