export async function getClientData() {
  let type = '';
  let link = '';

  // Pegando a url atual
  let url = window.location.origin;

  // Verificando se a URL é default
  const isDefaultURL =
    url.includes('oqdigital') ||
    url.includes('web.app') ||
    url.includes('indiquecredcrea') ||
    url.includes('maisjuntos') ||
    url.includes('ganhemais') ||
    url.includes('localhost') ||
    url.includes('firebaseapp');

  // Entrou aqui, buscar pelo activeDomain

  if (isDefaultURL) {
    const clientAliasLocalStorage = localStorage.getItem('clientAlias');
    const clientAliasURL = window.location.pathname.includes('/_/')
      ? window.location.pathname.replace('/_/', '')
      : '';

    // primeiro, verificar o localstorage
    if (clientAliasLocalStorage && clientAliasLocalStorage !== 'not-found') {
      //verifica se o alias do localstorage é o mesmo da url
      if (clientAliasLocalStorage === clientAliasURL) {
        console.log('ifequalclientAliasLocalStorage', clientAliasLocalStorage);
        localStorage.setItem('clientAlias', clientAliasLocalStorage);
        localStorage.removeItem('activeDomain');

        return { type: 'clientAlias', link: clientAliasLocalStorage };
      } else if (clientAliasLocalStorage && !clientAliasURL) {
        return { type: 'clientAlias', link: clientAliasLocalStorage };
      } else {
        //se for diferente sobrescreve pelo que tá no url
        console.log('ifnoequallocalAlias', clientAliasURL);
        localStorage.setItem('clientAlias', clientAliasURL);
        localStorage.removeItem('activeDomain');

        return { type: 'clientAlias', link: clientAliasURL };
      }
    }

    //se não existir o clientAlias no localstorage, verifica pela URL
    if (clientAliasURL.trim() !== '') {
      console.log('clientAliasURL', clientAliasURL);
      localStorage.setItem('clientAlias', clientAliasURL);
      localStorage.removeItem('activeDomain');

      return { type: 'clientAlias', link: clientAliasURL };
    }

    // A URL é default, mas não tem o ALIAS na url e nem no localstorage, redireciona para /not-found
    if (clientAliasURL.trim() === '' && !clientAliasLocalStorage) {
      localStorage.removeItem('activeDomain');
      localStorage.removeItem('clientAlias');

      window.location.href = `/not-found`;
      return;
    }
  }

  const activeDomain = window.location.hostname;
  type = 'activeDomain';
  link = activeDomain;
  localStorage.setItem('activeDomain', activeDomain);
  localStorage.removeItem('clientAlias');

  return { type, link };
}
