import axios from 'axios';

function getAPIURL() {
  switch (process.env.REACT_APP_VERSION) {
    case 'qa':
      return 'https://api.qa.oqdigital.com';
    case 'production':
      return 'https://api3.oqdigital.com';
    default:
      return 'http://localhost:5000/oqhomapi/us-central1';
  }
}

export const api = axios.create({
  baseURL: getAPIURL(),
});
