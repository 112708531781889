import { useState, FormEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { ForgotPasswordModal } from '../../components/Modals/ForgotPasswordModal';
import { useAuth } from '../../hooks/useAuth';
import * as routes from '../../routes/constants';
import { getClientData } from '../../utils/getClientData';

import './styles.css';

export function Login() {
  const {
    client,
    loginUser,
    statusLogin,
    authenticatedUser,
    loadingAuthenticatedUser,
    getClientDataByAlias,
  } = useAuth();
  const history = useHistory();

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isOpenforgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState(false);

  useEffect(() => {
    async function loadClientLogin() {
      let methodGet = await getClientData();

      const isUrl = methodGet!.type === 'activeDomain' ? true : false;

      await getClientDataByAlias(methodGet!.link, isUrl);
    }

    loadClientLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticatedUser) {
      return history.push(routes.DASHBOARD);
    }
  }, [authenticatedUser, history]);

  async function login(event: FormEvent) {
    const responseLoginUser = await loginUser(
      event,
      userEmail,
      userPassword,
      client?.uid
    );
    if (responseLoginUser) {
      return history.push(routes.DASHBOARD);
    }
  }

  function toggleModal() {
    setIsOpenForgotPasswordModal(!isOpenforgotPasswordModal);
  }

  return (
    <>
      {loadingAuthenticatedUser ? (
        <Loading type="page" />
      ) : (
        <>
          <Helmet>
            <title>
              {`${client?.companyName ? client?.companyName : 'OQ Digital'}`} -
              OQ Scan
            </title>
            <link
              rel="shortcut icon"
              href={client?.favIcon ? client?.favIcon : ''}
            />
          </Helmet>
          <nav className="z-depth-0 white"></nav>
          <div className="container">
            <img
              id="lgn-image-logo"
              src={
                client?.favIcon
                  ? client?.favIcon
                  : 'https://image.flaticon.com/icons/png/512/3873/3873961.png'
              }
              alt=""
            />
            <h1 id="lgn-title">Olá</h1>
            <p id="lgn-subtitle">Faça login.</p>
            <br />
            <form onSubmit={login}>
              <div className="input-field">
                <input
                  type="text"
                  className="browser-default custom-input"
                  placeholder="E-mail"
                  required
                  onChange={(event) => setUserEmail(event.target.value)}
                />
              </div>
              <div className="input-field">
                <input
                  type="password"
                  className="browser-default custom-input"
                  placeholder="Senha"
                  required
                  onChange={(event) => setUserPassword(event.target.value)}
                />
              </div>
              <span onClick={toggleModal} className="clickable">
                Esqueceu a senha? / <b>Redefina agora</b>
              </span>
              <br />
              <br />
              <br />
              <button
                type="submit"
                className="btn-large blue custom-btn custom-btn-full z-depth-0 waves-effect"
              >
                {/* Login */}
                {statusLogin}
              </button>
            </form>
          </div>
          <ForgotPasswordModal
            isOpen={isOpenforgotPasswordModal}
            onCloseModal={toggleModal}
          />
        </>
      )}
    </>
  );
}
