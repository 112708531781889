import { Routes } from './routes';
import { HelmetProvider } from 'react-helmet-async';

import { ScanContextProvider } from './contexts/ScanContext';
import { AuthContextProvider } from './contexts/AuthContext';
import { MetricContextProvider } from './contexts/MetricContext';
import './styles/global.css';

export function App() {
  return (
    <AuthContextProvider>
      <ScanContextProvider>
        <MetricContextProvider>
          <HelmetProvider>
            <Routes />
          </HelmetProvider>
        </MetricContextProvider>
      </ScanContextProvider>
    </AuthContextProvider>
  );
}
