import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import * as routes from './constants';
import { Loading } from '../components/Loading';

interface PrivateRouteProps extends RouteProps {
  isPrivate: boolean;
}

export function PrivateRoute({
  isPrivate,
  component: Component,
  children,
  ...rest
}: PrivateRouteProps) {
  const { authenticatedUser, loadingAuthenticatedUser } = useAuth();

  if (!Component) return null;

  if (loadingAuthenticatedUser) {
    return <Loading type="page" />;
  }

  // if (!authenticatedUser) {
  //   return <Loading type="page" />;
  // }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticatedUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.START} />
        )
      }
    />
  );
}
